<template>
  <page class="h-screen main-container">
    <div class="flex flex-col items-center justify-center h-full">
      <img
        src="/content/images/Alchemy.svg"
        alt="under-construction"
      />
      <div class="flex flex-col gap-2 text-center mt-8">
        <p class="h5">Page Under Construction</p>
        <p class="p1 text-bb-text-secondary">
          This page is undergoing exciting updates and is temporarily unavailable. <br />
          Check back later to explore the new feature.
        </p>
      </div>
    </div>
  </page>
</template>
<script>
import Page from '@/components/base/page/Page.vue'
export default {
  name: 'UnderConstruction',
  components: { Page },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}
</style>
